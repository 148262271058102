import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import axios from 'axios'

const setupAxios = () => {
  // axios.defaults.baseURL = 'https://cmp-all-api.herokuapp.com/'

  // axios.defaults.baseURL = 'https://wild-cyan-tadpole-gown.cyclic.app/'
  axios.defaults.baseURL = 'https://cmp-backend.onrender.com/'


  //axios.defaults.baseURL = 'http://localhost:5000'

  axios.defaults.headers = {
    'Cache-Control': 'no-cache,no-store',
    'Pragma': 'no-cache',
    'Expires': '0',
  };
};

setupAxios();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
